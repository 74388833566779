import jquery from "jquery";

window.$ = jquery;
window.jQuery = jquery;

window.jQuery(() => {
  if (import.meta.env.DEV) {
    console.info(`jQuery: ${window.$.fn.jquery}`);
  }
});
